<template>
  <div class="search_type_list">
    <span class="category_floor_name">{{ changeNumToHan(index+1) }}级分类：</span>
    <!-- <span class="category_floor_name" v-if="resourceNavType !== 2">{{ changeNumToHan(index+1) }}级分类：</span> -->
    <!-- <span class="category_floor_name" v-if="resourceNavType === 2">试题分类：</span> -->
    <div class="category_items">
      <span
        v-for="(item, index) in menu.list"
        :key="index"
        class="search_type_item"
        :data-id="item.id"
        :class="{ active: map.key === item.id }"
        @click="handleClick(item)"
      >{{ item.categoryName }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { changeNumToHan } from '@/utils/index'

export default defineComponent({
  name: 'CategoryList',
  props: {
    menu: {
      type: Object,
      default: () => {
        return { active: '', list: [] }
      }
    },
    index: {
      type: Number,
      default: () => {
        return 0
      }
    },
    resourceNavType: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  setup (props, { emit }) {
    const { menu, index, resourceNavType } = toRefs(props)
    const map = reactive({
      key: menu.value.active
    })
    const handleClick = (row) => {
      if (row.id !== map.key) {
        map.key = row.id
        emit('change', index.value, row)
      }
    }
    return {
      map,
      menu,
      handleClick,
      changeNumToHan
    }
  }
})
</script>

<style scoped lang="scss">
.search_type_list {
  // min-height: 50px;
  display: flex;
  .category_floor_name {
    min-width: 70px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    color: #1A1A1A;
  }
  .category_items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  & + .search_type_list {
    // margin-top: 10px;
  }

  .search_type_item {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 14px;
    padding: 6px 10px;
    &.not_right {
      margin-right: 0;
    }

    &.active {
      color: #2256f6;
      font-weight: bold;
      background: rgba(34, 86, 246, 0.05);
      border-radius: 5px;
    }
  }
}
</style>
